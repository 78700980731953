<script>
    import Layout from "@/router/layouts/main";
    import appConfig from "@/app.config";
    import PageHeader from "@/components/page-header";
    import Swal from "sweetalert2";
    import axios from "axios";
    /**
     * Dashboard Component
     */
    export default {
        page: {
            title: "Tambah Dokumen Persyaratan",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        components: {
            Layout,
            PageHeader,
        },
        data() {
            return {
                title: "Tambah Dokumen Persyaratan",
                items: [
                    {
                        text: "Master",
                        href: "/",
                    },
                    {
                        text: "Dokumen Persyaratan",
                        href: "/master/dokumen-persyaratan",
                    },
                    {
                        text: "Tambah Dokumen Persyaratan",
                        active: true,
                    },
                ],
                // Catch Error Axios
                axiosCatchError: null,
                // variable Page
                mdp_nama: null,
                mdp_is_mandatori: 'f',
                mdp_is_tanggal_awal: 'f',
                mdp_is_tanggal_berakhir: 'f',
                mdp_is_isian_custom: 'f',
                mdp_label_isian_custom: null,

            };
        },
        mounted() {},
        methods:{
            // Method dari masing masing fungsi di dalam file ini
            StoreData() {
                let self = this;
                Swal.fire({
					title: '<i class="fas fa-spinner fa-spin"></i>',
					text: "Loading...",
					showConfirmButton: false,
				});
                axios
                .post(process.env.VUE_APP_BACKEND_URL_VERSION + "master/dokumen-persyaratan", {
                        mdp_nama: self.mdp_nama,
                        mdp_is_mandatori: self.mdp_is_mandatori,
                        mdp_is_tanggal_awal: self.mdp_is_tanggal_awal,
                        mdp_is_tanggal_berakhir: self.mdp_is_tanggal_berakhir,
                        mdp_is_isian_custom: self.mdp_is_isian_custom,
                        mdp_label_isian_custom: self.mdp_label_isian_custom,
                    },
                    {headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    }
                })
                .then(function (response) {
                    console.log(response)
                    let timerInterval;
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Anda akan diarahkan ke halaman master dokumen persyaratan",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            self.$router.push({ name: "all-dokumen-persyaratan" });
                        }
                    });
                }).catch(e => {
                    this.axiosCatchError = e.response.data.data
                    Swal.close();
                })
			},
        },
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <b-form class="p-2" @submit.prevent="StoreData">
                        <div class="card-body">
                            <div v-if="axiosCatchError">
                                <div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' || axiosCatchError instanceof String">
                                {{axiosCatchError}}
                                </div>
                                <div class="alert alert-danger" role="alert" v-else>
                                    <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                                        <div v-for="(allErrors, idx) in errorArray" :key="idx">
                                            <span class="text-danger">{{ allErrors[0]}} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group class="mb-3" label="Nama Dokumen Persyaratan" label-for="formrow-nama-dokumen-persyaratan-input">
                                        <b-form-input id="formrow-nama-dokumen-persyaratan-input" type="text" v-model="mdp_nama"></b-form-input>
                                    </b-form-group>
                                    <b-form-checkbox id="checkbox-mdp_is_mandatori" v-model="mdp_is_mandatori" name="mdp_is_mandatori" value="t" unchecked-value="f">
                                        Apakah Mandatori ?
                                    </b-form-checkbox>
                                    <b-form-checkbox id="checkbox-mdp_is_tanggal_awal" v-model="mdp_is_tanggal_awal" name="mdp_is_tanggal_awal" value="t" unchecked-value="f">
                                        Isian Tanggal Awal ?
                                    </b-form-checkbox>
                                    <b-form-checkbox id="checkbox-mdp_is_tanggal_berakhir" v-model="mdp_is_tanggal_berakhir" name="mdp_is_tanggal_berakhir" value="t" unchecked-value="f">
                                        Isian Tanggal Akhir ?
                                    </b-form-checkbox>
                                    <b-form-checkbox id="checkbox-mdp_is_isian_custom" v-model="mdp_is_isian_custom" name="mdp_is_isian_custom" value="t" unchecked-value="f">
                                        Isian Text Custom ?
                                    </b-form-checkbox>
                                    <b-form-group class="mb-3" label="Nama Dokumen Persyaratan" label-for="formrow-nama-label-isian-custom-input" v-if="mdp_is_isian_custom == 't'">
                                        <b-form-input id="formrow-nama-label-isian-custom-input" type="text" v-model="mdp_label_isian_custom"></b-form-input>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="text-end">
                                <b-button type="submit" variant="primary"><i class="fa fa-save"></i> Simpan</b-button>
                            </div>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
    </Layout>
</template>
